"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.make_startCapture = void 0;
const loadScript = (scriptUrlToLoad, callback) => {
    var _a;
    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.src = scriptUrlToLoad;
    scriptTag.onload = callback;
    const scripts = document.getElementsByTagName("script");
    if (scripts.length > 0) {
        (_a = scripts[0].parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(scriptTag, scripts[0]);
    }
    else {
        document.body.appendChild(scriptTag);
    }
};
const make_startCapture = (posthog) => () => {
    var _a;
    // According to the rrweb docs, rrweb is not supported on IE11 and below:
    // "rrweb does not support IE11 and below because it uses the MutationObserver API which was supported by these browsers."
    // https://github.com/rrweb-io/rrweb/blob/master/guide.md#compatibility-note
    //
    // However, MutationObserver does exist on IE11, it just doesn't work well and does not detect all changes.
    // Instead, when we load "recorder.js", the first JS error is about "Object.assign" being undefined.
    // Thus instead of MutationObserver, we look for this function and block recording if it's undefined.
    if (typeof Object.assign === "undefined") {
        return;
    }
    if (!((_a = posthog.sessionRecording) === null || _a === void 0 ? void 0 : _a.captureStarted)) {
        posthog.sessionRecording.captureStarted = true;
        loadScript(posthog.sessionRecording.instance.get_config("api_host") +
            "/static/recorder.js?v=" +
            "1.34.0" /*Config.LIB_VERSION*/, () => {
            patchRrwebRecord();
            posthog.sessionRecording._onScriptLoaded(); //.bind(posthog.sessionRecording);
            startURLObserver();
        });
    }
};
exports.make_startCapture = make_startCapture;
const getRrwebRecord = () => window.rrweb ? window.rrweb.record : window.rrwebRecord;
const patchRrwebRecord = () => {
    const forcedOptions = { userTriggeredOnInput: true };
    const originalRrwebRecord = getRrwebRecord();
    const newRrwebRecord = (object) => {
        originalRrwebRecord(Object.assign(Object.assign({}, object), forcedOptions));
    };
    for (const f in originalRrwebRecord) {
        if (originalRrwebRecord[f].bind) {
            //@ts-ignore
            newRrwebRecord[f] = originalRrwebRecord[f].bind(newRrwebRecord);
        }
    }
    if (window.rrweb) {
        window.rrweb.record = newRrwebRecord;
    }
    else {
        window.rrwebRecord = newRrwebRecord;
    }
};
let currentURL;
const startURLObserver = () => {
    const onURLChange = () => {
        setTimeout(() => {
            const url = window.location.href;
            if (url === currentURL) {
                return;
            }
            currentURL = url;
            getRrwebRecord().addCustomEvent("url-change", {
                url,
            });
        });
    };
    window.addEventListener("popstate", (event) => {
        onURLChange();
    });
    window.addEventListener("locationchange", (event) => {
        onURLChange();
    });
    window.addEventListener("hashchange", (event) => {
        onURLChange();
    });
    const that = this;
    const pushState = window.history.pushState;
    window.history.pushState = function ( /*state*/) {
        onURLChange();
        //@ts-ignore
        return pushState.apply(window.history, arguments);
    };
    const replaceState = window.history.replaceState;
    window.history.replaceState = function ( /*state*/) {
        onURLChange();
        //@ts-ignore
        return replaceState.apply(window.history, arguments);
    };
};
